<template>
  <AppHeader v-if="order" class="header">
    <BackButton :to="{ name: 'order-bags', params: { id } }" />
  </AppHeader>
  <ScreenLoading
    v-if="loading || processing"
    blur
    :title="$t('components.review-assigning-storage-zones.loading-title')"
  />
  <div class="note fixed-footer-content fixed-footer-content--extra-padding">
    <div class="note__body">
      <div class="note__header">
        <div class="note__image">
          <img
            src="@/assets/images/print-labels@x2.png"
            alt=""
            class="ut-responsive-img"
            data-e2e="storage-zones-screen-image"
          />
        </div>
        <h1
          class="note__title"
          data-e2e="storage-zones-screen-title"
          ref="titleRef"
        >
          {{ $t('components.review-assigning-storage-zones.title.text') }}
        </h1>
        <h2 class="note__subtitle" data-e2e="storage-zones-screen-sub-title">
          {{ $t('components.review-assigning-storage-zones.description.text') }}
        </h2>
      </div>
      <div class="labels-list" ref="scrollTo" v-if="storageZones">
        <Label
          v-for="item in storageZones"
          :data-e2e-id="item.storageZoneId?.title"
          :title="`${item.storageZoneId?.title} Label`"
          :temperatureClasses="item.storageZoneId?.temperatureClasses"
          :key="item.storageZoneId.id"
          :quantity="item.quantity"
          :quantityOriginal="999"
          @update:quantity="setStorageZonesQuantity(item, $event)"
          @focusIn="focusInHandler"
          @focusOut="focusOutHandler"
        />
      </div>
    </div>
  </div>

  <footer
    class="footer fixed-footer-content__footer"
    v-show="!isStorageZoneFocused"
  >
    <div>
      <div class="footer__info">
        <p class="row__label row--accent" data-e2e="labels-label-needed-title">
          {{ $t('components.review-assigning-storage-zones.labels.text') }}
        </p>
        <p class="row__value row--accent" data-e2e="labels-count-label">
          {{ boxLabelNeeded }}
        </p>
      </div>
      <div class="footer__info">
        <p
          class="row__label row--sub-accent"
          data-e2e="labels-labels-needed-title"
        >
          {{ $t('components.review-assigning-storage-zones.bags.text') }}
        </p>
        <p class="row__value row--sub-accent" data-e2e="labels-count-bags">
          {{ boxBagsNeeded }}
        </p>
      </div>
    </div>

    <Button
      class="column--full"
      :disabled="processing"
      btnOutline
      @click="goNext"
      data-e2e="confirm-button"
    >
      <span class="ut-font_fix">{{ confirmationButtonLabel }}</span>
    </Button>
  </footer>

  <Popup
    :visible="isPopupVisible"
    :isCloseButtonExist="false"
    isContentCentered
    isSpaceToContentExist
    withoutHeader
  >
    <h3 class="popup__title">
      {{
        $t('components.review-assigning-storage-zones.not-enough-labels.title')
      }}
    </h3>
    <p class="popup__text">
      {{
        $t(
          'components.review-assigning-storage-zones.not-enough-labels.content',
        )
      }}
    </p>
    <Button data-e2e="confirm-popup-button" @click="closePopup">{{
      $t('components.review-assigning-storage-zones.not-enough-labels.confirm')
    }}</Button>
  </Popup>
</template>

<script lang="ts">
import { defineComponent, watch, ref, onMounted } from 'vue';
import { ordersServicePlugin } from '@/features/orders';
import { useStorageZonesStorage } from '@/features/bags/composables';
import { AppHeader, Label, Button, BackButton } from '@/features/ui';
import Popup from '@/features/ui/components/Popup.vue';
import ScreenLoading from '@/features/ui/components/ScreenLoading.vue'; // NOTE:  Failed to resolve component, when importing via barrel
import { configurationServicePlugin } from '@/features/configuration';
import { $t } from '@/i18n';
import { useOrderLabels } from '@/features/label-print/composables';
import { useOrder } from '../composables';
import { PrinterFeatureOptions } from '../types';
import { UsedStorageZone } from '@/features/orders';

export default defineComponent({
  components: {
    Popup,
    Label,
    Button,
    AppHeader,
    BackButton,
    ScreenLoading,
  },
  props: {
    id: { type: String, required: true },
  },
  setup(props) {
    const scrollTo = ref<null | HTMLElement>(null);
    const {
      order,
      boxLabelNeeded,
      boxBagsNeeded,
      loadOrder,
      setStorageZonesQuantity,
      storageZones,
      loading,
      setOrderStorageZones,
    } = useOrder();
    const { redirectToPrintPage } = useOrderLabels(order);
    const {
      saveStorageZonesQuantityToStorage,
      getStorageZonesQuantityFromStorage,
    } = useStorageZonesStorage();
    const processing = ref(false);
    const titleRef = ref(Element);
    const isPopupVisible = ref(false);

    const closePopup = (): void => {
      isPopupVisible.value = false;
    };

    const isPrintingFeatureActive = ref(true);
    const printerFeatureOption = ref('');

    onMounted(async () => {
      await getStorageZones();
      isPrintingFeatureActive.value = (
        await configurationServicePlugin
          .get()
          .isFeatureActive('printingStorageLabels')
      ).value;

      printerFeatureOption.value = await configurationServicePlugin
        .get()
        .getFeatureOption('printingStorageLabels', 'printerType', 'string');
    });

    let confirmationButtonLabel = $t(
      'components.review-assigning-storage-zones.confirmation-button-label.continue',
    );
    if (isPrintingFeatureActive.value) {
      confirmationButtonLabel =
        printerFeatureOption.value === PrinterFeatureOptions.ZebraQrCodePrinter
          ? $t(
              'components.review-assigning-storage-zones.confirmation-button-label.generate-qr',
            )
          : $t(
              'components.review-assigning-storage-zones.confirmation-button-label.print-label',
            );
    }

    const setOrderStorageZonesQuantityFromStorage = () => {
      const storedStorageZones = getStorageZonesQuantityFromStorage();
      if (storedStorageZones && Array.isArray(storedStorageZones)) {
        storedStorageZones.forEach((storedStorageZones) => {
          const storageZone = order.value?.storageZones.find(
            (bagItem) =>
              bagItem.storageZoneId.id === storedStorageZones.storageZoneId,
          );

          if (storageZone) {
            setStorageZonesQuantity(storageZone, storedStorageZones.quantity);
          }
        });
      }
    };

    const compareStorageZonesByIdDescending = (
      previousBag: UsedStorageZone,
      nextBag: UsedStorageZone,
    ) =>
      parseInt(nextBag.storageZoneId.id, 10) -
      parseInt(previousBag.storageZoneId.id, 10);

    const initWatchers = () => {
      watch(
        storageZones,
        () => {
          if (order.value)
            saveStorageZonesQuantityToStorage(storageZones.value);
        },
        { deep: true },
      );
      watch(
        order,
        async () => {
          await setOrderStorageZones();
          setOrderStorageZonesQuantityFromStorage();
          storageZones.value?.sort(compareStorageZonesByIdDescending);
        },
        { immediate: true },
      );
    };

    const getStorageZones = async () => {
      await loadOrder(props.id);
      initWatchers();
    };

    const goNext = async () => {
      if (!boxLabelNeeded.value || boxLabelNeeded.value < boxBagsNeeded.value) {
        isPopupVisible.value = true;
        return;
      }

      processing.value = true;

      if (order.value) {
        await ordersServicePlugin.get().saveOrder(order.value);
      }

      await redirectToPrintPage(props.id);
    };

    const isStorageZoneFocused = ref(false);
    const focusInHandler = () => {
      isStorageZoneFocused.value = true;
      scrollTo.value?.scrollIntoView();
    };
    const focusOutHandler = () => {
      isStorageZoneFocused.value = false;
    };

    return {
      storageZones,
      goNext,
      order,
      boxLabelNeeded,
      boxBagsNeeded,
      setStorageZonesQuantity,
      isStorageZoneFocused,
      focusInHandler,
      focusOutHandler,
      processing,
      titleRef,
      scrollTo,
      isPrintingFeatureActive,
      printerFeatureOption,
      confirmationButtonLabel,
      loading,
      isPopupVisible,
      closePopup,
    };
  },
});
</script>
<style lang="scss" scoped>
.header {
  position: absolute;
  padding: 16px;
  background: transparent;
}

.note {
  padding-top: 10px;
  &__title {
    margin: 20px auto 0;
    font-size: 21px;
    line-height: 1.42em;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 1.625em;
    font-weight: normal;
  }
}

.note__image {
  margin: 0 auto;
  max-width: 100px;
}

.labels-list {
  display: grid;
  margin-top: 16px;
}

.footer {
  &__info {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-items: left;
    margin-bottom: 8px;
    .labels-error-text {
      display: flex;
      align-items: center;
      font-size: 14px;
      text-align: left;
      color: #e00016;
      line-height: 24px;
      letter-spacing: 0.2px;
      svg {
        margin-right: 4px;
      }
    }
  }
}

.row__value,
.row__label {
  line-height: 1em;
}

.row__value {
  justify-self: end;
}

.row__value--accent {
  font-size: 18px;
}

.row--accent {
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
}

.row--sub-accent {
  font-size: 16px;
  line-height: 28px;
}

.column--full {
  grid-column: 1/-1;
}

.ut-responsive-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.popup {
  &__title {
    margin-bottom: 8px;
    font-size: 21px;
    font-weight: 600;
    line-height: 1.4em;
  }

  &__text {
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.625em;
  }
}
</style>
